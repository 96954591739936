import React from "react";
import Navbar from "../components/Navbar/Navbar";
import LandingSec from "../components/LandingSec/LandingSec";
import DownloadSec from "../components/DownloadSec/DownloadSec";
import DemoSec from "../components/DemoSec/DemoSec";
import WorkingSec from "../components/WorkingSec/WorkingSec";
import ReviewSec from "../components/ReviewSec/ReviewSec";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <>
      <div id="mainSec" className="imageBack">
        <Navbar />
        <LandingSec />
      </div>
      <div className="container-fluid">
        <div className="row quote">
          <div className="col-lg-12 text-center d-flex justify-content-center align-items-center">
            <h1>
              "ActivePass, is the easiest way to book a court near you to play
              all kinds of sports."
            </h1>
          </div>
        </div>
      </div>
      <WorkingSec />
      <DemoSec />
      <ReviewSec />
      <DownloadSec />
      <Footer />
    </>
  );
};

export default Home;
