import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReviewItem from "../ReviewItem/ReviewItem";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1520, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div
        className={currentSlide === 0 ? "disable nav-btn" : "nav-btn"}
        onClick={() => previous()}
      >
        <FaArrowLeft />
      </div>
      <div className="nav-btn" onClick={() => next()}>
        <FaArrowRight />
      </div>
    </div>
  );
};

const ReviewSec = () => {
  const carouselRef = useRef(null);

  return (
    <div id="reviewSec" className="container-fluid reviewSec">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h1 className="reviews-heading">
            What people Think
            <span className="highlight"> About Us</span>
          </h1>
        </div>
        <div className="col-lg-12 text-center">
          <ButtonGroup
            next={() => carouselRef.current.next()}
            previous={() => carouselRef.current.previous()}
            carouselState={{ currentSlide: 0 }}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-12">
          <Carousel
            responsive={responsive}
            ref={carouselRef}
            arrows={false}
            renderButtonGroupOutside={true}
          >
            <ReviewItem
              name="Deepak"
              img="images/Deepak.png"
              rating="4.5"
              review="“I'm obsessed with ActivePass! I used to hate finding a pickleball court that was available. This app has saved me so much time and frustration. The interface is so intuitive. Now, I spend less time on booking and more playing!”"
            />
            <ReviewItem
              name="Rithika J"
              img="images/Rithika.jpeg"
              rating="4.7"
              review="“ActivePass is a lifesaver for busy people like me. I don't have a lot of free time, so being able to book a squash court quickly and easily is a huge plus. The app is super straightforward, and I was able to book my court in minutes“"
            />
            <ReviewItem
              name="Akhil"
              img="images/Akhil.png"
              rating="4.9"
              review="“Wow! ActivePass is a game changer. I never thought booking a court would be so easy. I was able to reserve a padel court for me and my friends in literally 3 steps! No more calling around different places and hoping for availability.“"
            />
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ReviewSec;
