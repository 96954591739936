import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer">
        <footer className="py-5">
          <div className="row">
            <div className="col-lg-2 col-sm-4">
              <h5>Quick Links</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 ">
                    Home
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 ">
                    Working
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 ">
                    Demo
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 ">
                    Reviews
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-sm-4">
              <h5>Company</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link className="nav-link p-0" to={"/privacy"}>
                    Privacy Policy
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link p-0" to={"/terms"}>
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-sm-4">
              <h5>Support</h5>
              <p className="desc">activepass.support@strawket.com</p>
            </div>

            <div className="col-lg-4 col-sm-12 mt-sm-5 mb-sm-3 mt-lg-0 mb-lg-0 offset-lg-1">
              <form>
                <h5>Subscribe to our newsletter</h5>
                <p className="desc">Monthly digest of whats new and exciting from us.</p>
                <div className="d-flex w-100 gap-2">
                  <label htmlFor="newsletter1" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="newsletter1"
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                  />
                  <button className="btn btnSolid" type="button">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
          <hr/>
          <div className="d-flex justify-content-between pt-4 mt-4">
            <p>&copy; 2025 ActivePass, Inc. All rights reserved.</p>
            <ul className="list-unstyled d-flex">
              <li className="ms-3">
                <a className="link-dark" href="/">
                  <FaFacebook style={styles.icon} />
                </a>
              </li>
              <li className="ms-3">
                <a className="link-dark" href="/">
                  <FaXTwitter style={styles.icon} />
                </a>
              </li>
              <li className="ms-3">
                <a className="link-dark" href="/">
                  <FaInstagram style={styles.icon} />
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </>
  );
};

const styles = {
  icon: {
    fontSize: "25px",
    color: "white",
  },
};

export default Footer;
