import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='docs' style={{ padding: '20px', lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <h2>STRAWKET LEARNING INC. PRIVACY POLICY</h2>
      <p>LAST UPDATED: 25th Feb 2025</p>
      <h2>1. OVERVIEW</h2>
      <p>
        At STRAWKET LEARNING INC. (the “Company”, “we” or “us”), we use the ActivePass website,
        mobile apps and other internet-based tools for the following purpose:
        Listing and booking sports facilities
      </p>
      <p>
        We are required to collect and retain information about the people and companies that access
        and use the website (“Users” or “you”). This Policy applies to our privacy practices on all
        communications between the Company and Users, including but not limited to communication
        via:
      </p>
      <ol>
        <li>the Company website (including the website hosted at <a href="http://www.activepass.app">www.activepass.app</a>,
        and any other web domain used by the Company to communicate with users now or in the future);</li>
        <li>any mobile, computer, or tablet-based application currently offered by Company or developed
        in the future by the Company; and</li>
        <li>all other services provided by the Company, as described on the Website (Items 1 to 3 are
        collectively referred to as the "Website").</li>
      </ol>
      <p>
        This Privacy Policy (“Policy”) sets out how we use, protect, collect and preserve information
        submitted by Users, including User information gathered by our technology. This Policy should
        be read in conjunction with any Terms of Use or policies available on the Website.
      </p>
      <p>
        By accessing or submitting any information through the Website, you are agreeing to the terms
        of this Policy. You are prohibited from accessing or using the Website unless you fully
        understand and agree to this Policy and all Terms of Use available for review on the Website.
      </p>
      <p>
        If you have any questions about this Policy, please contact us by sending an email with your
        questions to <a href="mailto:support@strawket.com">support@strawket.com</a> before accessing or entering any information on the
        Website.
      </p>
      <h2>2. INFORMATION ABOUT USERS WE COLLECT AND STORE</h2>
      <p>
        When you choose to create an account on the Website, we will collect and retain all personal
        identifying information submitted to us. We will take steps to protect the information submitted
        to us by Users and we will not disclose any personal identifying information without the consent
        of Users, unless otherwise set out in this Policy. Personal identifying information may include
        but is not necessarily limited to:
      </p>
      <ul>
        <li>Full Name</li>
        <li>Email</li>
        <li>Phone Number</li>
        <li>Address</li>
        <li>Gender</li>
        <li>Age</li>
        <li>Location</li>
      </ul>
      <p>
        We will not use personal identifying information to contact you for any reason unrelated to your
        use of the Website or for commercial reasons unrelated to the business of the Company. Except
        with your consent or where required by law, we will not provide personal identifying information
        to any third party.
      </p>
      <h2>3. UPLOADING PERSONAL INFORMATION</h2>
      <p>
        In order to facilitate their interactions on the Website, Users are able, where they deem
        appropriate, to upload information including personal identifying information. Users should only
        upload information to the extent that such information enhances their experience of using the
        Website.
      </p>
      <h2>4. ADDITIONAL DATA COLLECTED</h2>
      <p>
        To learn about your experience as a User and to make periodic improvements to the Website
        and our business, we may collect and retain certain information. We will automatically record
        information about your activity on the Website. This may include your Internet Protocol (IP)
        address, operating system, geolocation information, locale preferences, identification numbers
        associated with your computers and mobile devices, your mobile carrier, date and time stamps
        associated with transactions, system configuration information, metadata, and other electronic
        interactions with the Company and the Website.
      </p>
      <h2>5. COOKIES</h2>
      <p>
        To facilitate the collection of information and to enhance the experience of Users of the
        Website, we use cookies which may be stored on Users’ computers and/or devices so that we
        can recognize Users when they return. You may set your browser to notify you when you receive
        a cookie or to not accept certain cookies. However, changing such settings may result in you
        not being able to use the Website, either in its entirety or certain features.
      </p>
      <p>
        Using cookies, you may have the option to allow us to save your user ID and login password for
        future logins to the Website. Cookies that are stored on your computer and/or device after
        visiting the Website may be recognized for advertising purposes and to show you targeted
        advertisements after you visit the Website.
      </p>
      <p>
        For more information on the cookies we use or to opt-out, please contact us at
        <a href="mailto:support@strawket.com">support@strawket.com</a>.
      </p>
      <h2>6. REMARKETING</h2>
      <p>
        We may engage in "remarketing" activities using third-party services, which may include but are
        not limited to Google Ads, Google Analytics, and Facebook. By using the Website, you consent
        to allow us to provide information to third party service parties who may present you with
        advertisements about the Company after you visit the Website. We will provide third-party
        providers of remarketing services with the minimum amount of information required to facilitate
        remarketing, and in no circumstances will the Company provide any personal identifying
        information to third-parties for the purpose of remarketing.
      </p>
      <h2>7. PERMITTED USES OF DATA</h2>
      <p>
        We use data collected from the Website for various purposes, including to: provide the Website
        to you and accompanying customer support; notify you about updates and changes to the
        Website; prevent and address technical issues with the Website; monitor usage of the Website.
      </p>
      <p>
        Without restriction, we may use any information you submit through the Website for any
        purpose we deem appropriate so long as it is anonymous and stripped of any and all personal
        identifying information.
      </p>
      <p>
        We may use the data collected from the Website, devices, browsers, and any other sources, for
        its own purposes, both related to and unrelated to improving the Website.
      </p>
      <h2>8. LEGAL BASIS FOR PROCESSING PERSONAL IDENTIFYING INFORMATION</h2>
      <p>
        We may process your personal identifying information for a number of reasons, including:
      </p>
      <ul>
        <li>performing a contract with you relating to the Website;</li>
        <li>you have provided us consent to do so;</li>
        <li>fulfilling a legal obligation;</li>
        <li>fulfilling our legitimate interests, which are not overridden by your rights;</li>
        <li>for payment processing purposes.</li>
      </ul>
      <h2>9. ACCESSING, CORRECTING & DELETING YOUR PERSONAL INFORMATION</h2>
      <p>
        If you wish to access or amend your personal information or to request that we permanently
        delete your personal information from our servers, please send an email to
        <a href="mailto:support@strawket.com">support@strawket.com</a>. We will ensure your personal information is accessible, amended
        and/or deleted in a reasonable time after receiving that request. Deleting such information may
        require us to terminate any account you may have created in its entirety and prevent you from
        using the Website. So long as your account remains active, we will preserve your personal
        information in accordance with the Policy.
      </p>
      <p>
        You may decline to share certain personal information with us, in which case you may not be
        able to use the Website, either in its entirety or certain features.
      </p>
      <p>
        If you have not accessed your account for a total of 2 years, we may terminate your account,
        without notice, along with any personal information retained by us.
      </p>
      <p>
        When deleting your information, whether by request or due to inactivity, we will use standard
        electronic means to remove your personal information from our files.
      </p>
      <p>
        We will also retain any and all information that we are required to retain under any applicable
        laws for the full duration of time required under those laws.
      </p>
      <h2>10. KEEPING YOUR DATA SECURE</h2>
      <p>
        We will store your information in electronic format on industry-standard servers that we have
        selected. Except where required to do so by law, we will not store your personal information in
        any physical or paper-based format.
      </p>
      <p>
        The servers we select to store information engage security software and generally accepted
        standards to protect personal information. The software used by these servers is intended to
        prevent unauthorized access or improper use.
      </p>
      <p>
        However, no security system is perfect and there is always a risk of unauthorized parties
        accessing your personal information. Therefore, we make no guarantees or representations as
        to the technical or legal compliance of the servers we use. We cannot guarantee the complete
        protection and security of your personal information.
      </p>
      <p>
        Our servers may be located outside of Canada, and thus are potentially subject to the privacy
        and security laws in force in other countries. If you have concerns about your personal
        information being stored electronically in foreign jurisdictions, please contact us before
        proceeding to use the Website.
      </p>
      <h2>11. SECURITY BREACHES</h2>
      <p>
        In the event the personal information of a User is disclosed as the result of any breach of
        security of the Website or its servers, regardless of who is at fault, we will take steps to advise
        all known affected Users within a reasonable timeframe of learning of the breach.
      </p>
      <h2>12. THIRD-PARTY APPLICATIONS</h2>
      <p>
        To provide an enhanced experience to Users, including to track conversions and analytics, we
        may engage services and features controlled by third-parties. We cannot control or dictate how
        any third-party application you use in conjunction with the Website, now or at any point in the
        future, will treat any personal information you transmit through their servers when using the
        Website. We advise you to contact these parties directly to review their privacy policies.
      </p>
      <p>
        Without limitation, the Company uses or may use the following third-party applications and
        services in conjunction with the Website: Google Analytics, Meta Pixel, Hotjar, Google Tag
        Manager, Stripe, AWS.
      </p>
      <p>
        By using any of these third-party applications to interact with the Website, we will not be given
        access to your login credentials, user name, password, payment information, or any information
        you may provide directly to these applications.
      </p>
      <h2>13. USE OF WEBSITE BY MINORS</h2>
      <p>
        The Website is not intended to be accessed by persons under the age of 18. The Company does
        not engage in any marketing efforts aimed at children or minors. Persons under the age of 18
        are prohibited from using the Website in any manner or providing any information to the
        Company. In the event Company is provided with any information, including personal identifying
        information, relating to a minor, that information will be immediately deleted without notice.
      </p>
      <h2>14. DISCLOSURE OF PERSONAL INFORMATION</h2>
      <p>
        In certain situations, we may be required to disclose personal data in response to lawful
        requests by public authorities, including to meet national security or law enforcement
        requirements.
      </p>
      <p>
        We may disclose your personal information to third parties:
      </p>
      <ul>
        <li>
          In the event that we sell or buy any business or assets, in which case we may disclose your
          personal data to the prospective seller or buyer of such business or assets.
        </li>
        <li>
          If the Company or its assets are acquired by a third party, in which case personal data about
          Users may be one of the transferred assets.
        </li>
        <li>
          If we are under a duty to disclose or share your personal data in order to comply with any
          legal obligation, or in order to enforce or apply our Terms of Use; or to protect the rights,
          property, or safety of the Company, our Users, or others. This includes exchanging information
          with other companies and organizations for the purposes of fraud protection and credit risk
          reduction.
        </li>
      </ul>
      <h2>15. USER REFERRAL SERVICE</h2>
      <p>
        You can choose to provide us with the names and email addresses of individuals or
        organizations that you feel would be interested in learning more about our products and
        services. When you choose to engage this service, we may send a preliminary email to each
        address provided to us, inviting the contact to visit our website. We continue to store the contact
        details in order to track the success of our referral service.
      </p>
      <h2>16. AMENDING THIS POLICY</h2>
      <p>
        This Policy may be updated and amended from time to time. We reserve the right to change this
        Policy at any time, and any amended Policy is effective upon posting to the Website. We will
        make efforts to communicate any changes to this Policy we deem material, in our sole
        discretion, via email or notifications on the Website. Your continued use of the Website will be
        deemed acceptance of any amended Policy.
      </p>
      <h2>17. CONTACT US</h2>
      <p>
        We encourage you to send us questions and inquiries on this Policy and the steps we take to
        keep your personal information secure. Please send us an email: <a href="mailto:support@strawket.com">support@strawket.com</a>. We will
        respond to all inquiries on the Policy within 30 days.
      </p>
    </div>
  )
}

export default PrivacyPolicy
